.root {
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 1.6rem 0;

  img {
    max-width: min(100%, 200px);
  }
}
