.root {
  display: flex;
  justify-content: center;
  padding: 0 1.6rem 1.6rem;
}

.image {
  width: 100%;
}

.introduction {
  margin: 2.4rem 0 !important;
}

h1 {
  margin: 0;
}
